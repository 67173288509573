<template>
  <div class="jump">
    <!-- <p style="24px">
      正在跳转到支付页面，请稍候~
    </p> -->
    <img src="@/assets/images/loading.gif" alt="" />
  </div>
</template>

<script>
import { getRedirectUrl } from "@/api/api";
import config from "@/config";
import channelUserIdUtil from "@/utils/channelUserId";
import wayCodeUtils from "@/utils/wayCode";
// import ap from "@/utils/alipayjsapi.min.js";
// import wayCodeUtils from "@/utils/wayCode";

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
export default {
  components: {},

  mounted () {
    //如果未获取到路由配置,跳转到对应的错误页面

    console.log("正在跳转", this.$route.query);
    let { channelUserId, mode } = this.$route.query;
    let payOrderNo = config.cachePayParams || this.$route.query.payOrderNo;
    if (!channelUserId) {
      getRedirectUrl(payOrderNo, mode).then((result) => {
        console.log(result);
        let payWay = wayCodeUtils.getPayWay()
        if (payWay.name == 'zfb') {
          //如果小程序内打开页面,则通过小程序API获取授权
          let returnUrl = location.origin + "/pages/Oauth2Callback/" + payOrderNo + "?app_id=" + result.data.appId;
          location.href = returnUrl;
          return
        }
        //如果非小程序内,通过URL 跳转获取授权
        location.href = result.data.redirectUrl;
      })
        .catch((err) => {
          this.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        });
      return
    }

    //设置channelUserId
    channelUserIdUtil.setChannelUserId(channelUserId);

    if (config.mode && config.mode == 'med-cashier') {
      this.$router.push({
        name: 'MedCashier',
        query: { payOrderNo: payOrderNo },
      });
      return
    }

    this.$router.push({
      name: wayCodeUtils.getPayWay().routeName,
      query: { payOrderNo: payOrderNo },
    });
    // getPayOrderInfo().then((res) => {
    //   let payOrderRes = res.data;
    //   if (!payOrderRes.channelUser) {

    //   }
    // })
    // .catch((err) => {
    //   this.$router.push({
    //     name: config.errorPageRouteName,
    //     params: { errInfo: err.message },
    //   });
    // });

    // qrCodeScan(this.$route.query)
    //   .then((res) => {
    //     if (res.code == 0) {
    //       payOrderNo = res.data;
    //       //以下为不存在
    //       let returnUrl =
    //         location.origin + "/pages/Oauth2Callback?payOrderNo=" + payOrderNo;

    //       getRedirectUrl(payOrderNo, encodeURIComponent(returnUrl))
    //         .then((result) => {
    //           if (wayCodeUtils.getPayWay().routeName == "CashierAlipay") {
    //             location.href = returnUrl + "&app_id=" + result.data.appId;
    //           } else {
    //             location.href = result.data.redirectUrl;
    //           }
    //         })
    //         .catch((err) => {
    //           this.$router.push({
    //             name: config.errorPageRouteName,
    //             params: { errInfo: err.message },
    //           });
    //         });
    //     }
    //   })
    //   .catch((err) => {
    //     this.$router.push({
    //       name: config.errorPageRouteName,
    //       params: { errInfo: err.message },
    //     });
    //   });
  },
  methods: {
    IntegrationParams (params) {
      const Stringify = (value) => {

        if (typeof value == "object") {
          return JSON.stringify(value)
        } else {
          return value
        }
      }
      let query = '';
      let keys = Object.keys(params); //获取对象的key 返回对象key的数组
      if (keys.length > 0) {
        query = keys.reduce((pre, cur) => {
          return pre + cur + '=' + Stringify(params[cur]) + '&';
        }, '?').slice(0, -1);
      }
      return query
    },
  }
};
</script>
<style lang="css" scoped>
.jump {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
